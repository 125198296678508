export default {
  /*
    '0xf36547a7A7749c611333125B8Ce772Dcf50c19D6': {
        "description": "자체제작 NFT 연결 테스트",
        "avatar": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
        "banner": {
          "large": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/img/logo.svg",
          "small": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/img/logo.svg"
        }
    },
    '0x919b42A9beC839d0D7a2b87216CEE8De45e14FCD': {
      "description": "외부 토큰 연결 테스트 1",
      "avatar": "https://nft-market.scv.sh/logo.png",
      "banner": {
        "large": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png",
        "small": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png"
      }
    },
    '0x899Bf674EB19C9FFFC46627194311FA69d7471E5': {
      "description": "외부 토큰 연결 테스트 2",
      "avatar": "https://nft-market.scv.sh/images/nfts/book.png",
      "banner": {
        "large": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png",
        "small": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png"
      }
    },
    */

    // rinkeby
    '0xb753Ec8Ee4a2B55155615819985e4B7B56549730': { // pancake bunny
      "description": "KONDOR",
      "avatar": "https://campfire.camp/logo.png",
      "banner": {
        "large": "https://gateway.pinata.cloud/ipfs/QmSYsecFreWrgS7hyZTKFqt3cfZsNFD83aVNaRXUMbw2RW",
        "small": "https://gateway.pinata.cloud/ipfs/QmSYsecFreWrgS7hyZTKFqt3cfZsNFD83aVNaRXUMbw2RW"
      }
    },
    '0x634F22FC01DDbBdEd82A72f2f1649b4f8C825d94': {
      "description": "로고 모음",
      "avatar": "https://gateway.pinata.cloud/ipfs/QmRg4yvF2w5K927EGHUi9dp55SHyWWh7pGUvgLbApxB7mq",
      "banner": {
        "large": "https://gateway.pinata.cloud/ipfs/QmRg4yvF2w5K927EGHUi9dp55SHyWWh7pGUvgLbApxB7mq",
        "small": "https://gateway.pinata.cloud/ipfs/QmRg4yvF2w5K927EGHUi9dp55SHyWWh7pGUvgLbApxB7mq"
      }
    },
}
