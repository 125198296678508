import React, { useMemo } from 'react'
import { Flex, Skeleton, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import { DeserializedPool } from 'state/types'
import { useCakeVault } from 'state/pools/hooks'
import { getBalanceNumber } from 'utils/formatBalance'
import BaseCell, { CellContent } from './BaseCell'

interface TotalStakedCellProps {
  pool: DeserializedPool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const TotalStakedCell: React.FC<TotalStakedCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { sousId, stakingToken, totalStaked, isAutoVault } = pool
  const { totalCakeInVault } = useCakeVault()

  const isManualCakePool = sousId === 0

  const totalStakedBalance = useMemo(() => {
    // if (isAutoVault) {
    //   return getBalanceNumber(totalCakeInVault, stakingToken.decimals)
    // }
    // if (isManualCakePool) {
    //   const manualCakeTotalMinusAutoVault = new BigNumber("12500000000000000000000")
    //  return getBalanceNumber(manualCakeTotalMinusAutoVault, stakingToken.decimals)
    // }
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }, [totalStaked, stakingToken.decimals])

  return (
    <StyledCell role="cell">
        <CellContent>
          {totalStaked && totalStaked.gte(0) ? (
            <>
            <Text fontSize="12px" color="textSubtle" textAlign="left">
              {t('Total staked')}
            </Text>
            
              <Flex height="20px" alignItems="center">
                <Balance fontSize="16px" value={totalStakedBalance} decimals={0} unit={` ${stakingToken.symbol}`} />
              </Flex>
            </>
          ) : (
            <></>
          )}
        </CellContent>      
    </StyledCell>
  )
}

export default TotalStakedCell
