export default {
  masterChef: {
    97: '',
    56: '',
    4: '0x6eBF705F2F075aA66c140879bAdA4E04DFd4Ad53',
    1: '0xB83508bB360Ad2c8726ba6E1746D03d4BCac387C',
  },
  sousChef: {
    97: '',
    56: '',
  },
  lotteryV2: {
    97: '',
    56: '',
  },
  multiCall: {
    56: '',
    97: '',
    4: '0x011519bFbEFE0D9C9B30fD6a05DCCE85EB5bc9f1',
    1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  },
  pancakeProfile: {
    56: '',
    97: '',
  },
  pancakeRabbits: {
    56: '',
    97: '',
    4: '0xb753Ec8Ee4a2B55155615819985e4B7B56549730',
    1: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
  },
  easterNft: {
    56: '',
    97: '',
  },
  cakeVault: {
    56: '',
    97: '',
    4: '',
    1: '',
  },
  predictions: {
    56: '',
    97: '',
  },
  chainlinkOracle: {
    56: '',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '',
    97: '',
  },
  bunnySpecialLottery: {
    56: '',
    97: '',
  },
  farmAuction: {
    56: '',
    97: '',
  },
  AnniversaryAchievement: {
    56: '',
    97: '',
  },
  nftMarket: {
    56: '',
    97: '',
    4: '0x1f41A830cb011d364dd14Ac8D0B38c50Abb5832d',
    1: '',
  },
  mintingStation: {
    56: '',
    97: '',
    4: '0x4132f97aA9217cafA805744bDCA5CB0C75b6bCe0',
    1: '',
  },
  nftSale: {
    56: '',
    97: '',
  },
  pancakeSquad: {
    56: '',
    97: '',
  },
}
