import { useCallback } from 'react'
import { ethers, Contract, CallOverrides } from 'ethers'
import { get } from 'lodash'
import { simpleRpcProvider } from 'utils/providers'



/**
 * Perform a contract call with a gas price returned from useGasPrice
 * @param contract Used to perform the call
 * @param methodName The name of the method called
 * @param methodArgs An array of arguments to pass to the method
 * @param overrides An overrides object to pass to the method. gasPrice passed in here will take priority over the price returned by useGasPrice
 * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
 */
export function useCallWithGasPrice() {
  const callWithGasPrice = useCallback(
    async (
      contract: Contract,
      methodName: string,
      methodArgs: any[] = [],
      overrides: CallOverrides = null,
    ): Promise<ethers.providers.TransactionResponse> => {
      const contractMethod = get(contract, methodName)
      // const hasManualGasPriceOverride = overrides?.gasPrice
      // const gasPrice = await simpleRpcProvider.getGasPrice()
      const { gasPrice, maxFeePerGas, maxPriorityFeePerGas } = await simpleRpcProvider.getFeeData()

      const tx = await contractMethod(
        ...methodArgs,
        { ...overrides, maxFeePerGas, maxPriorityFeePerGas },
      )
      
      return tx
    },
    [],
  )

  return { callWithGasPrice }
}
