import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'FIRE',
    lpAddresses: {
      4: '0x2A3320B50DFd4402AdC3394313198104a2079913',
      1: '0xA548a7efE00371ded670cBdD9CAdcb383506C718',
    },
    token: serializedTokens.fire,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'ETH-CAMP LP',
    lpAddresses: {
      4: '0x8533368A1bad679CEF24eB9991AFD69dd0F2031e',
      1: '0x9a5ba50d225f30C17305662C8e7A44106356AFdF',
    },
    token: serializedTokens.camp,
    quoteToken: serializedTokens.weth,
    isCommunity: false,
  },
  {
    pid: 1,
    lpSymbol: 'CAMP-FIRE LP',
    lpAddresses: {
      4: '0x6E51455ba5a2a6725911E4928FDB536B6d942C3A',
      1: '0xB5d147337097D5552150c01E14f5ed03cBf645a7',
    },
    token: serializedTokens.fire,
    quoteToken: serializedTokens.camp,
    isCommunity: false,
  },
]

export default farms
