import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.fire,
    earningToken: serializedTokens.fire,
    contractAddress: {
      // MasterChef
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      4: '0x6eBF705F2F075aA66c140879bAdA4E04DFd4Ad53',
      1: '0xB83508bB360Ad2c8726ba6E1746D03d4BCac387C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.25',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: serializedTokens.camp,
    earningToken: serializedTokens.fire,
    contractAddress: {
      97: '',
      56: '',
      4: '0x86d07b72578eFFf8c4597165C3f630C97cF4fE27',
      1: '0xB83508bB360Ad2c8726ba6E1746D03d4BCac387C'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '1.25',
    isFinished: false,
  },
]

export default pools
