import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const ETH_BLOCK_TIME = 12

export const BASE_ETH_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://www.etherscan.io',
  [ChainId.TESTNET]: 'https://www.etherscan.io',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
  [ChainId.HOMESTEAD]: 'https://www.etherscan.io',
}

// FIRE_PER_BLOCK details
// 40 FIRE is minted per block
// 20 FIRE per block is sent to Burn pool (A farm just for burning cake)
// 10 FIRE per block goes to FIRE syrup pool
// 9 FIRE per block goes to Yield farms and lottery
// FIRE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// FIRE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const FIRE_PER_BLOCK = 5
export const BLOCKS_PER_YEAR = (60 / ETH_BLOCK_TIME) * 60 * 24 * 365 // 2628000
export const FIRE_PER_YEAR = FIRE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://campfire.camp'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_ETH_SCAN_URL = BASE_ETH_SCAN_URLS[ChainId.HOMESTEAD]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANFIRE_BUNNIES_UPDATE_FREQUENCY = 8000
