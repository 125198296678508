import { request, gql } from 'graphql-request'
import { stringify } from 'qs'
import { GRAPH_API_NFTMARKET, API_NFT } from 'config/constants/endpoints'
import { getPancakeRabbitsAddress } from 'utils/addressHelpers'
import { getErc721Contract, getPancakeRabbitContract, getNftMarketContract } from 'utils/contractHelpers'
import { ethers } from 'ethers'
import map from 'lodash/map'
import { uniq } from 'lodash'
import { pancakeBunniesAddress } from 'views/Nft/market/constants'
import nftInfos from 'config/constants/nftInfos'
import {
  TokenMarketData,
  ApiCollections,
  TokenIdWithCollectionAddress,
  NftToken,
  NftLocation,
  Collection,
  ApiResponseCollectionTokens,
  ApiResponseSpecificToken,
  ApiCollection,
  CollectionMarketDataBaseFields,
  Transaction,
  AskOrder,
  ApiSingleTokenData,
  NftAttribute,
  ApiTokenFilterResponse,
  ApiCollectionDistribution,
  ApiCollectionDistributionPB,
} from './types'
import { getBaseNftFields, getBaseTransactionFields, getCollectionBaseFields, getCollectionAllData } from './queries'

/**
 * API HELPERS
 */

/**
 * Fetch static data from all collections using the API
 * @returns
 */
export const getCollectionsApi = async (): Promise<ApiCollection[]> => {
  const collections = await getAllCollectionsSg()

  // get call collection address
  // because graphql returns lowercase address
  const market = getNftMarketContract()
  const res = await market.viewCollections(0, 10000)

  const lowerCollectionAddr: string[] = []
  for (let i = 0; i < res.collectionAddresses.length; i++) {
    lowerCollectionAddr.push(res.collectionAddresses[i].toLowerCase());
  }

  const result: ApiCollection[] = []
  for (let i = 0; i<collections.length; i++){
    const collection = collections[i]
    const ids = []
    collection.nfts?.forEach(nft => {
      const id = nft.otherId === undefined || nft.otherId === null ? nft.tokenId : nft.otherId

      if(ids.indexOf(id)<0){
        ids.push(id)
      }
    });
    
    // get real address(not lowercase)
    let address = collection.id

    const index = lowerCollectionAddr.indexOf(address.toLowerCase())
    if(index >= 0){
      address = res.collectionAddresses[index]
    }
    const nftInfo = nftInfos[address]

    if (nftInfo){
      result.push({
        "address": address,
        "owner": collection.creatorAddress,
        "name": collection.name,
        "description": nftInfo.description,
        "symbol": collection.symbol,
        "totalSupply": ids.length.toString(),
        "verified": true,
        "createdAt": "2020-12-11T02:32:55.000Z",
        "updatedAt": "2020-12-11T02:32:55.000Z",
        "avatar": nftInfo.avatar,
        "banner": {
          "large": nftInfo.banner.large,
          "small": nftInfo.banner.small
        }
      })
    }
  }

  return result
  // return [
  //   {
  //     "address": "0xf36547a7A7749c611333125B8Ce772Dcf50c19D6",
  //     "owner": "0x5C483Dd1d3dBf931651535090adD2e69b881498d",
  //     "name": "CAMPFIRE",
  //     "description": "CAMPFIRE NFT",
  //     "symbol": "PB",
  //     "totalSupply": "2",
  //     "verified": true,
  //     "createdAt": "2020-12-11T02:32:55.000Z",
  //     "updatedAt": "2020-12-11T02:32:55.000Z",
  //     "avatar": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //     "banner": {
  //       "large": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png",
  //       "small": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-sm.png"
  //     }
  //   },
  // ]
  
  // const res = await fetch(`${API_NFT}/collections`)
  // if (res.ok) {
  //   const json = await res.json()
  //   return json.data
  // }
  // console.error('Failed to fetch NFT collections', res.statusText)
  // return []
}

/**
 * Fetch static data from a collection using the API
 * @returns
 */
export const getCollectionApi = async (collectionAddress: string): Promise<ApiCollection> => {
  // return {
  //   "address": "0xf36547a7A7749c611333125B8Ce772Dcf50c19D6",
  //   "owner": "0x5C483Dd1d3dBf931651535090adD2e69b881498d",
  //   "name": "CAMPFIRE",
  //   "description": "CAMPFIRE NFT",
  //   "symbol": "PB",
  //   "totalSupply": "1",
  //   "verified": true,
  //   "createdAt": "2020-12-11T02:32:55.000Z",
  //   "updatedAt": "2020-12-11T02:32:55.000Z",
  //   "avatar": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //   "banner": {
  //     "large": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-lg.png",
  //     "small": "https://static-nft.pancakeswap.com/mainnet/0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07/banner-sm.png"
  //   },
  //   "attributes": [
  //     {
  //       "traitType": "bunnyId",
  //       "value": "0",
  //       "displayType": null
  //     },
  //   ]
  // }

  const collectionData = await getCollectionAllInfo(collectionAddress)

  // const otherIds = []
  // collectionData.nfts?.forEach(nft => {
  //   if(otherIds.indexOf(nft.otherId)<0){
  //     otherIds.push(nft.otherId)
  //   }
  // });

  // const attributes = []
  // otherIds.forEach(id => {
  //   attributes.push({
  //     "traitType": "bunnyId",
  //     "value": id.toString(),
  //     "displayType": null
  //   })
  // });

  const ids = []
  collectionData.nfts?.forEach(nft => {
    const id = nft.otherId === undefined || nft.otherId === null ? nft.tokenId : nft.otherId

    if(ids.indexOf(id)<0){
      ids.push(id)
    }
  });

  const attributes = []
  ids.forEach(id => {
    attributes.push({
      "traitType": "bunnyId",
      "value": id.toString(),
      "displayType": null
    })
  });

  const nftInfo = nftInfos[collectionAddress]

  const result = {
    "address": collectionAddress,
    "owner": collectionData.creatorAddress,
    "name": collectionData.name,
    "description": nftInfo.description,
    "symbol": collectionData.symbol,
    "totalSupply": ids.length.toString(),
    "verified": true,
    "createdAt": "2020-12-11T02:32:55.000Z",
    "updatedAt": "2020-12-11T02:32:55.000Z",
    "avatar": nftInfo.avatar,
    "banner": {
      "large": nftInfo.banner.large,
      "small": nftInfo.banner.small
    },
    "attributes": attributes

  }

  return result
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}`)
  // if (res.ok) {
  //   const json = await res.json()
  //   return json.data
  // }
  // console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  // return null
}

/**
 * Fetch static data for all nfts in a collection using the API
 * @param collectionAddress
 * @param size
 * @param page
 * @returns
 */
export const getNftsFromCollectionApi = async (
  collectionAddress: string,
  size = 100,
  page = 1,
): Promise<ApiResponseCollectionTokens> => {
  // return {
  //   "attributesDistribution": {
  //     // "0": 112,
  //     "0": 1,
  //   },
  //   "total": 1,
  //   "data": {
  //     "0": {
  //       "name": "CAMPFIRE logo",
  //       "description": "This is SCVSoft logo image!",
  //       "image": {
  //         "original": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //         "thumbnail": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //         "mp4": null,
  //         "webm": null,
  //         "gif": null
  //       },
  //       "collection": {
  //         "name": "CAMPFIRE"
  //       }
  //     },
  //     }
  //   }

  // const isPBCollection = collectionAddress.toLowerCase() === pancakeBunniesAddress.toLowerCase()
  // const requestPath = `${API_NFT}/collections/${collectionAddress}/tokens${
  //   !isPBCollection ? `?page=${page}&size=${size}` : ``
  // }`

  const collectionData = await getCollectionAllInfo(collectionAddress)

  const otherIds = []
  const attributesDistribution = {}
  const dataParse: { [id: string] : any } = {} // Dictionary라고 뭔가 선언 해줘함 (-_-;)


  const market = getNftMarketContract()
  const res = await market.viewCollections(0, 10000)
  let address = collectionAddress

  for (let i = 0; i < res.collectionAddresses.length; i++) {
    if(address === res.collectionAddresses[i].toLowerCase()){
      address =  res.collectionAddresses[i]
      break
    }
  }

  const nftInfo = nftInfos[address]

  /* eslint-disable no-await-in-loop */
  for (let i = 0; i< collectionData.nfts.length; i++){
    const nft = collectionData.nfts[i]
    /* eslint-disable no-lonely-if */
    if (collectionAddress === getPancakeRabbitsAddress())
    {
      if(otherIds.indexOf(nft.otherId) < 0){
        otherIds.push(nft.otherId)
        attributesDistribution[nft.otherId] = 1
      
        const contract = getPancakeRabbitContract() // This is kind of hardcode (need to use just ERC721 contract)
        const bunnyname: string = await contract.getBunnyName(nft.otherId)

        dataParse[nft.otherId.toString()] = {
          name: bunnyname,
          description: nftInfo.description,
          image: {
            original: nft.metadataUrl,
            thumbnail: nft.metadataUrl,
            mp4: null,
            webm: null,
            gif: null
          },
          collection: {
            name: collectionData.name
          }
        }
      }
      else{
        attributesDistribution[nft.otherId] += 1
      }
    }
    else{
      if(otherIds.indexOf(nft.tokenId) < 0){
        
        otherIds.push(nft.tokenId)
        attributesDistribution[nft.tokenId] = 1

        const name = `# ${nft.tokenId} NFT`

        dataParse[nft.tokenId.toString()] = {
          "name": name,
          description: nftInfo.description,
          image: {
            original: nft.metadataUrl,
            thumbnail: nft.metadataUrl,
            mp4: null,
            webm: null,
            gif: null
          },
          collection: {
            name: collectionData.name
          }
        }
      }
      else{
        attributesDistribution[nft.tokenId] += 1
      }
    }
  }

  const result = {
    "attributesDistribution": attributesDistribution,
    total: otherIds.length,
    data: dataParse
  }

  return result
}

/**
 * Fetch a single NFT using the API
 * @param collectionAddress
 * @param tokenId
 * @returns NFT from API
 */
export const getNftApi = async (
  collectionAddress: string,
  tokenId: string,
): Promise<ApiResponseSpecificToken['data']> => {

  let result
  const collectionData = await getCollectionAllInfo(collectionAddress)
  const collectionName = collectionData.name

  // get call collection address
  // because graphql returns lowercase address
  const market = getNftMarketContract()
  const res = await market.viewCollections(0, 10000)
  let address = collectionAddress

  for (let i = 0; i < res.collectionAddresses.length; i++) {
    if(address === res.collectionAddresses[i].toLowerCase()){
      address =  res.collectionAddresses[i]
      break
    }
  }

  const nftInfo = nftInfos[address]

  if(!nftInfo)
    {
      console.error(`API: Can't fetch NFT token ${tokenId} in ${collectionAddress}`, res.status)
      return null
    }

  if (address === getPancakeRabbitsAddress())
  {
    const contract = getPancakeRabbitContract() // This is kind of hardcode (need to use just ERC721 contract)
    const name = await contract.getBunnyNameOfTokenId(tokenId);
    const uri = await contract.tokenURI(tokenId);
    const bunnyId = await contract.getBunnyId(tokenId);

    const otherIds = []
    collectionData.nfts?.forEach(nft => {
      if(otherIds.indexOf(nft.otherId)<0){
        otherIds.push(nft.otherId)
      }
    });

    result = {

      "tokenId": tokenId,
      "name": name,
      "description": nftInfo.description,
      "image": {
        "original": uri,
        "thumbnail": uri,
        "mp4": null,
        "webm": null,
        "gif": null
      },
      "createdAt": "2021-02-20T02:52:30.609Z",
      "updatedAt": "2021-04-20T08:00:37.964Z",
      "attributes": [
        {
          "traitType": "bunnyId",
          "value": bunnyId.toString(),
          "displayType": null
        }
      ],
      "collection": {
        "name": collectionName
      }
    }
  }
  else{
    const contract = getErc721Contract(address) // This is kind of hardcode (need to use just ERC721 contract)
    const uri = await contract.tokenURI(tokenId)
    const name = `# ${tokenId} NFT`

    result = {

      "tokenId": tokenId,
      "name": name,
      "description": nftInfo.description,
      "image": {
        "original": uri,
        "thumbnail": uri,
        "mp4": null,
        "webm": null,
        "gif": null
      },
      "createdAt": "2021-02-20T02:52:30.609Z",
      "updatedAt": "2021-04-20T08:00:37.964Z",
      "attributes": [
        {
          "traitType": "tokenId",
          "value": tokenId,
          "displayType": null
        }
      ],
      "collection": {
        "name": collectionName
      }
    }
  }

  return result

  // return {

  //     "tokenId": "3",
  //     "name": "CAMPFIRE",
  //     "description": "This is CAMPFIRE NFT",
  //     "image": {
  //       "original": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //       "thumbnail": "https://raw.githubusercontent.com/SCV-Soft/SCV-Soft.github.io/master/favicon-228.png",
  //       "mp4": null,
  //       "webm": null,
  //       "gif": null
  //     },
  //     "createdAt": "2021-02-20T02:52:30.609Z",
  //     "updatedAt": "2021-04-20T08:00:37.964Z",
  //     "attributes": [
  //       {
  //         "traitType": "bunnyId",
  //         "value": "0",
  //         "displayType": null
  //       }
  //     ],
  //     "collection": {
  //       "name": "CAMPFIRE"
  //     }
    
  // }
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}`)
  // if (res.ok) {
  //   const json = await res.json()
  //   return json.data
  // }

  // console.error(`API: Can't fetch NFT token ${tokenId} in ${collectionAddress}`, res.status)
  // return null
}

/**
 * Fetch a list of NFT from different collections
 * @param from Array of { collectionAddress: string; tokenId: string }
 * @returns Array of NFT from API
 */
export const getNftsFromDifferentCollectionsApi = async (
  from: { collectionAddress: string; tokenId: string }[],
): Promise<NftToken[]> => {
  const promises = from.map((nft) => getNftApi(nft.collectionAddress, nft.tokenId))
  const responses = await Promise.all(promises)
  // Sometimes API can't find some tokens (e.g. 404 response)
  // at least return the ones that returned successfully
  return responses
    .filter((resp) => resp)
    .map((res, index) => ({
      tokenId: res.tokenId,
      name: res.name,
      collectionName: res.collection.name,
      collectionAddress: from[index].collectionAddress,
      description: res.description,
      attributes: res.attributes,
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
      image: {
        original: res.image?.original,
        thumbnail: res.image?.thumbnail,
      },
    }))
}

/**
 * SUBGRAPH HELPERS
 */

/**
 * Fetch market data from a collection using the Subgraph
 * @returns
 */
export const getCollectionSg = async (collectionAddress: string): Promise<CollectionMarketDataBaseFields> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            ${getCollectionBaseFields()}
          }
        }
      `,
      { collectionAddress: collectionAddress.toLowerCase() },
    )
    return res.collection
  } catch (error) {
    console.error('Failed to fetch collection', error)
    return null
  }
}

/**
 * Fetch market data from a collection using the Subgraph
 * @returns
 */
 export const getCollectionAllInfo = async (collectionAddress: string): Promise<any> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            ${getCollectionAllData()}
          }
        }
      `,
      { collectionAddress: collectionAddress.toLowerCase() },
    )
    return res.collection
  } catch (error) {
    console.error('Failed to fetch collection', error)
    return null
  }
}

/**
 * Fetch market data from all collections using the Subgraph
 * @returns
 */
export const getCollectionsSg = async (): Promise<CollectionMarketDataBaseFields[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        {
          collections {
            ${getCollectionBaseFields()}
          }
        }
      `,
    )
    return res.collections
  } catch (error) {
    console.error('Failed to fetch NFT collections', error)
    return []
  }
}

/**
 * Fetch market data from all collections using the Subgraph
 * @returns
 */
export const getAllCollectionsSg = async (): Promise<any[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        {
          collections {
            ${getCollectionAllData()}
          }
        }
      `,
    )
    return res.collections
  } catch (error) {
    console.error('Failed to fetch NFT collections', error)
    return []
  }
}

/**
 * Fetch market data for nfts in a collection using the Subgraph
 * @param collectionAddress
 * @param first
 * @param skip
 * @returns
 */
export const getNftsFromCollectionSg = async (
  collectionAddress: string,
  first = 1000,
  skip = 0,
): Promise<TokenMarketData[]> => {
  // Squad to be sorted by tokenId as this matches the order of the paginated API return. For PBs - get the most recent,
  const isPBCollection = collectionAddress.toLowerCase() === pancakeBunniesAddress.toLowerCase()

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftCollectionMarketData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            id
            nfts(orderBy:${isPBCollection ? 'updatedAt' : 'tokenId'}, skip: $skip, first: $first) {
             ${getBaseNftFields()}
            }
          }
        }
      `,
      { collectionAddress: collectionAddress.toLowerCase(), skip, first },
    )
    return res.collection.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs from collection', error)
    return []
  }
}

/**
 * Fetch market data for PancakeBunnies NFTs by bunny id using the Subgraph
 * @param bunnyId - bunny id to query
 * @param existingTokenIds - tokens that are already loaded into redux
 * @returns
 */
export const getNftsByBunnyIdSg = async (
  bunnyId: string,
  existingTokenIds: string[],
  orderDirection: 'asc' | 'desc',
): Promise<TokenMarketData[]> => {
  try {
    const where =
      existingTokenIds.length > 0
        ? { otherId: bunnyId, isTradable: true, tokenId_not_in: existingTokenIds }
        : { otherId: bunnyId, isTradable: true }
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftsByBunnyIdSg($collectionAddress: String!, $where: NFT_filter, $orderDirection: String!) {
          nfts(first: 30, where: $where, orderBy: currentAskPrice, orderDirection: $orderDirection) {
            ${getBaseNftFields()}
          }
        }
      `,
      {
        collectionAddress: pancakeBunniesAddress.toLowerCase(),
        where,
        orderDirection,
      },
    )
    return res.nfts
  } catch (error) {
    console.error(`Failed to fetch collection NFTs for bunny id ${bunnyId}`, error)
    return []
  }
}

/**
 * Fetch market data for PancakeBunnies NFTs by bunny id using the Subgraph
 * @param bunnyId - bunny id to query
 * @param existingTokenIds - tokens that are already loaded into redux
 * @returns
 */
export const getMarketDataForTokenIds = async (
  collectionAddress: string,
  existingTokenIds: string[],
): Promise<TokenMarketData[]> => {
  try {
    if (existingTokenIds.length === 0) {
      return []
    }
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getMarketDataForTokenIds($collectionAddress: String!, $where: NFT_filter) {
          collection(id: $collectionAddress) {
            id
            nfts(first: 1000, where: $where) {
              ${getBaseNftFields()}
            }
          }
        }
      `,
      {
        collectionAddress: collectionAddress.toLowerCase(),
        where: { tokenId_in: existingTokenIds },
      },
    )
    return res.collection.nfts
  } catch (error) {
    console.error(`Failed to fetch market data for NFTs stored tokens`, error)
    return []
  }
}

export const getNftsMarketData = async (
  where = {},
  first = 1000,
  orderBy = 'id',
  orderDirection: 'asc' | 'desc' = 'desc',
  skip = 0,
): Promise<TokenMarketData[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftsMarketData($first: Int, $skip: Int!, $where: NFT_filter, $orderBy: NFT_orderBy, $orderDirection: OrderDirection) {
          nfts(where: $where, first: $first, orderBy: $orderBy, orderDirection: $orderDirection, skip: $skip) {
            ${getBaseNftFields()}
            transactionHistory {
              ${getBaseTransactionFields()}
            }
          }
        }
      `,
      { where, first, skip, orderBy, orderDirection },
    )

    return res.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

export const getAllPancakeBunniesLowestPrice = async (bunnyIds: string[]): Promise<Record<string, number>> => {
  try {
    const singlePancakeBunnySubQueries = bunnyIds.map(
      (
        bunnyId,
      ) => `b${bunnyId}:nfts(first: 1, where: { otherId: ${bunnyId}, isTradable: true }, orderBy: currentAskPrice, orderDirection: asc) {
        currentAskPrice
      }
    `,
    )
    const rawResponse: Record<string, { currentAskPrice: string }[]> = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getAllPancakeBunniesLowestPrice {
          ${singlePancakeBunnySubQueries}
        }
      `,
    )
    return Object.keys(rawResponse).reduce((lowestPricesData, subQueryKey) => {
      const bunnyId = subQueryKey.split('b')[1]
      return {
        ...lowestPricesData,
        [bunnyId]:
          rawResponse[subQueryKey].length > 0 ? parseFloat(rawResponse[subQueryKey][0].currentAskPrice) : Infinity,
      }
    }, {})
  } catch (error) {
    console.error('Failed to fetch PancakeBunnies lowest prices', error)
    return {}
  }
}

export const getAllPancakeBunniesRecentUpdatedAt = async (bunnyIds: string[]): Promise<Record<string, number>> => {
  try {
    const singlePancakeBunnySubQueries = bunnyIds.map(
      (
        bunnyId,
      ) => `b${bunnyId}:nfts(first: 1, where: { otherId: ${bunnyId}, isTradable: true }, orderBy: updatedAt, orderDirection: desc) {
        updatedAt
      }
    `,
    )
    const rawResponse: Record<string, { updatedAt: string }[]> = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getAllPancakeBunniesLowestPrice {
          ${singlePancakeBunnySubQueries}
        }
      `,
    )
    return Object.keys(rawResponse).reduce((updatedAtData, subQueryKey) => {
      const bunnyId = subQueryKey.split('b')[1]
      return {
        ...updatedAtData,
        [bunnyId]: rawResponse[subQueryKey].length > 0 ? Number(rawResponse[subQueryKey][0].updatedAt) : -Infinity,
      }
    }, {})
  } catch (error) {
    console.error('Failed to fetch PancakeBunnies latest market updates', error)
    return {}
  }
}

/**
 * Returns the lowest price of any NFT in a collection
 */
export const getLowestPriceInCollection = async (collectionAddress: string) => {
  try {
    const response = await getNftsMarketData(
      { collection: collectionAddress.toLowerCase(), isTradable: true },
      1,
      'currentAskPrice',
      'asc',
    )

    if (response.length === 0) {
      return 0
    }

    const [nftSg] = response
    return parseFloat(nftSg.currentAskPrice)
  } catch (error) {
    console.error(`Failed to lowest price NFTs in collection ${collectionAddress}`, error)
    return 0
  }
}

/**
 * Fetch user trading data for buyTradeHistory, sellTradeHistory and askOrderHistory from the Subgraph
 * @param where a User_filter where condition
 * @returns a UserActivity object
 */
export const getUserActivity = async (
  address: string,
): Promise<{ askOrderHistory: AskOrder[]; buyTradeHistory: Transaction[]; sellTradeHistory: Transaction[] }> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getUserActivity($address: String!) {
          user(id: $address) {
            buyTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            sellTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            askOrderHistory(first: 500, orderBy: timestamp, orderDirection: desc) {
              id
              block
              timestamp
              orderType
              askPrice
              nft {
                ${getBaseNftFields()}
              }
            }
          }
        }
      `,
      { address },
    )

    return res.user || { askOrderHistory: [], buyTradeHistory: [], sellTradeHistory: [] }
  } catch (error) {
    console.error('Failed to fetch user Activity', error)
    return {
      askOrderHistory: [],
      buyTradeHistory: [],
      sellTradeHistory: [],
    }
  }
}

/**
 * Get the most recently listed NFTs
 * @param first Number of nfts to retrieve
 * @returns NftTokenSg[]
 */
export const getLatestListedNfts = async (first: number): Promise<TokenMarketData[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getLatestNftMarketData($first: Int) {
          nfts(where: { isTradable: true }, orderBy: updatedAt , orderDirection: desc, first: $first) {
            ${getBaseNftFields()}
            collection {
              id
            }
          }
        }
      `,
      { first },
    )

    return res.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

/**
 * Filter NFTs from a collection
 * @param collectionAddress
 * @returns
 */
export const fetchNftsFiltered = async (
  collectionAddress: string,
  filters: Record<string, string | number>,
): Promise<ApiTokenFilterResponse> => {
  const res = await fetch(`${API_NFT}/collections/${collectionAddress}/filter?${stringify(filters)}`)

  if (res.ok) {
    const data = await res.json()
    return data
  }

  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null
}

/**
 * OTHER HELPERS
 */

export const getMetadataWithFallback = (apiMetadata: ApiResponseCollectionTokens['data'], bunnyId: string) => {
  // The fallback is just for the testnet where some bunnies don't exist
  return (
    apiMetadata[bunnyId] ?? {
      name: '',
      description: '',
      collection: { name: 'Pancake Bunnies' },
      image: {
        original: '',
        thumbnail: '',
      },
    }
  )
}

export const getPancakeBunniesAttributesField = (bunnyId: string) => {
  // Generating attributes field that is not returned by API
  // but can be "faked" since objects are keyed with bunny id
  return [
    {
      traitType: 'bunnyId',
      value: bunnyId,
      displayType: null,
    },
  ]
}

export const combineApiAndSgResponseToNftToken = (
  apiMetadata: ApiSingleTokenData,
  marketData: TokenMarketData,
  attributes: NftAttribute[],
) => {
  return {
    tokenId: marketData.tokenId,
    name: apiMetadata.name,
    description: apiMetadata.description,
    collectionName: apiMetadata.collection.name,
    collectionAddress: pancakeBunniesAddress,
    image: apiMetadata.image,
    marketData,
    attributes,
  }
}

export const fetchWalletTokenIdsForCollections = async (
  account: string,
  collections: ApiCollections,
): Promise<TokenIdWithCollectionAddress[]> => {
  const walletNftPromises = map(collections, async (collection): Promise<TokenIdWithCollectionAddress[]> => {
    const { address: collectionAddress } = collection
    const contract = getErc721Contract(collectionAddress)
    let balanceOfResponse

    try {
      balanceOfResponse = await contract.balanceOf(account)
    } catch (e) {
      console.error(e)
      return []
    }

    const balanceOf = balanceOfResponse.toNumber()

    // User has no NFTs for this collection
    if (balanceOfResponse.eq(0)) {
      return []
    }

    const getTokenId = async (index: number) => {
      try {
        const tokenIdBn: ethers.BigNumber = await contract.tokenOfOwnerByIndex(account, index)
        const tokenId = tokenIdBn.toString()
        return tokenId
      } catch (error) {
        console.error('getTokenIdAndData', error)
        return null
      }
    }

    const tokenIdPromises = []

    // For each index get the tokenId
    for (let i = 0; i < balanceOf; i++) {
      tokenIdPromises.push(getTokenId(i))
    }

    const tokenIds = await Promise.all(tokenIdPromises)
    const nftLocation = NftLocation.WALLET
    const tokensWithCollectionAddress = tokenIds.map((tokenId) => {
      return { tokenId, collectionAddress, nftLocation }
    })

    return tokensWithCollectionAddress
  })

  const walletNfts = await Promise.all(walletNftPromises)
  return walletNfts.flat()
}

/**
 * Helper to combine data from the collections' API and subgraph
 */
export const combineCollectionData = (
  collectionApiData: ApiCollection[],
  collectionSgData: CollectionMarketDataBaseFields[],
): Record<string, Collection> => {
  const collectionsMarketObj: Record<string, CollectionMarketDataBaseFields> = collectionSgData.reduce(
    (prev, current) => ({ ...prev, [current.id]: { ...current } }),
    {},
  )

  return collectionApiData.reduce((accum, current) => {
    const collectionMarket = collectionsMarketObj[current.address.toLowerCase()]
    const collection: Collection = {
      ...current,
      ...collectionMarket,
    }

    return {
      ...accum,
      [current.address]: collection,
    }
  }, {})
}

/**
 * Evaluate whether a market NFT is in a users wallet, their profile picture, or on sale
 * @param tokenId string
 * @param tokenIdsInWallet array of tokenIds in wallet
 * @param tokenIdsForSale array of tokenIds on sale
 * @param profileNftId Optional tokenId of users' profile picture
 * @returns NftLocation enum value
 */
export const getNftLocationForMarketNft = (
  nft: NftToken,
  nftsForSale: TokenMarketData[],
  walletNfts: TokenMarketData[], 
  profileNftId?: string
  // tokenId: string,
  // tokenIdsInWallet: string[],
  // tokenIdsForSale: string[],
  // profileNftId?: string,
): NftLocation => {

  // 프로필용 토큰인지 체크도 필요함
  if(nft.tokenId === profileNftId){
    return NftLocation.PROFILE
  }

  // 서로 다른 NFT끼리 토큰 ID가 동일한 경우 잘못 판단됨
  if(nftsForSale.find((marketNft) => marketNft.tokenId === nft.tokenId && marketNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase())){
    return NftLocation.FORSALE 
  }

  if(walletNfts.find((walletNft) => walletNft.tokenId === nft.tokenId && walletNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase())){
    return NftLocation.WALLET
  }

  // if (tokenId === profileNftId) {
  //   return NftLocation.PROFILE
  // }
  // if (tokenIdsForSale.includes(tokenId)) {
  //   return NftLocation.FORSALE
  // }
  // if (tokenIdsInWallet.includes(tokenId)) {
  //   return NftLocation.WALLET
  // }
  console.error(`Cannot determine location for tokenID ${nft.tokenId}, defaulting to NftLocation.WALLET`)
  return NftLocation.WALLET
}

/**
 * Construct complete TokenMarketData entities with a users' wallet NFT ids and market data for their wallet NFTs
 * @param walletNfts TokenIdWithCollectionAddress
 * @param marketDataForWalletNfts TokenMarketData[]
 * @returns TokenMarketData[]
 */
export const attachMarketDataToWalletNfts = (
  walletNfts: TokenIdWithCollectionAddress[],
  marketDataForWalletNfts: TokenMarketData[],
): TokenMarketData[] => {
  const walletNftsWithMarketData = walletNfts.map((walletNft) => {
    const marketData = marketDataForWalletNfts.find(
      (marketNft) =>
        marketNft.tokenId === walletNft.tokenId &&
        marketNft.collection.id.toLowerCase() === walletNft.collectionAddress.toLowerCase(),
    )
    return (
      marketData ?? {
        tokenId: walletNft.tokenId,
        collection: {
          id: walletNft.collectionAddress.toLowerCase(),
        },
        nftLocation: walletNft.nftLocation,
        metadataUrl: null,
        transactionHistory: null,
        currentSeller: null,
        isTradable: null,
        currentAskPrice: null,
        latestTradedPriceInBNB: null,
        tradeVolumeBNB: null,
        totalTrades: null,
        otherId: null,
      }
    )
  })
  return walletNftsWithMarketData
}

/**
 * Attach TokenMarketData and location to NftToken
 * @param nftsWithMetadata NftToken[] with API metadata
 * @param nftsForSale  market data for nfts that are on sale (i.e. not in a user's wallet)
 * @param walletNfts makret data for nfts in a user's wallet
 * @param tokenIdsInWallet array of token ids in user's wallet
 * @param tokenIdsForSale array of token ids of nfts that are on sale
 * @param profileNftId profile picture token id
 * @returns NFT[]
 */
export const combineNftMarketAndMetadata = (
  nftsWithMetadata: NftToken[],
  nftsForSale: TokenMarketData[],
  walletNfts: TokenMarketData[],
  tokenIdsInWallet: string[],
  tokenIdsForSale: string[],
  profileNftId?: string,
): NftToken[] => {
  const completeNftData = nftsWithMetadata.map<NftToken>((nft) => {
    // Get metadata object
    const isOnSale = nftsForSale.filter((forSaleNft) => forSaleNft.tokenId === nft.tokenId && forSaleNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase()).length > 0
    let marketData
    if (isOnSale) {
      marketData = nftsForSale.find((marketNft) => marketNft.tokenId === nft.tokenId && marketNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase())
    } else {
      marketData = walletNfts.find((marketNft) => marketNft.tokenId === nft.tokenId && marketNft.collection.id.toLowerCase() === nft.collectionAddress.toLowerCase())
    }
    const location = getNftLocationForMarketNft(nft, nftsForSale, walletNfts, profileNftId)
    return { ...nft, marketData, location }
  })
  return completeNftData
}

/**
 * Get in-wallet, on-sale & profile pic NFT metadata, complete with market data for a given account
 * @param account
 * @param collections
 * @param profileNftWithCollectionAddress
 * @returns Promise<NftToken[]>
 */
export const getCompleteAccountNftData = async (
  account: string,
  collections: ApiCollections,
  profileNftWithCollectionAddress?: TokenIdWithCollectionAddress,
): Promise<NftToken[]> => {
  const walletNftIdsWithCollectionAddress = await fetchWalletTokenIdsForCollections(account, collections)
  if (profileNftWithCollectionAddress?.tokenId) {
    walletNftIdsWithCollectionAddress.unshift(profileNftWithCollectionAddress)
  }

  const uniqueCollectionAddresses = uniq(
    walletNftIdsWithCollectionAddress.map((walletNftId) => walletNftId.collectionAddress),
  )

  const walletNftsByCollection = uniqueCollectionAddresses.map((collectionAddress) => {
    return {
      collectionAddress,
      idWithCollectionAddress: walletNftIdsWithCollectionAddress.filter(
        (walletNft) => walletNft.collectionAddress === collectionAddress,
      ),
    }
  })

  const walletMarketDataRequests = walletNftsByCollection.map((walletNftByCollection) => {
    const tokenIdIn = walletNftByCollection.idWithCollectionAddress.map((walletNft) => walletNft.tokenId)
    return getNftsMarketData({
      tokenId_in: tokenIdIn,
      collection: walletNftByCollection.collectionAddress.toLowerCase(),
    })
  })

  const walletMarketDataResponses = await Promise.all(walletMarketDataRequests)
  const walletMarketData = walletMarketDataResponses.flat()

  const walletNftsWithMarketData = attachMarketDataToWalletNfts(walletNftIdsWithCollectionAddress, walletMarketData)

  const walletTokenIds = walletNftIdsWithCollectionAddress
    .filter((walletNft) => {
      // Profile Pic NFT is no longer wanted in this array, hence the filter
      return profileNftWithCollectionAddress?.tokenId !== walletNft.tokenId
    })
    .map((nft) => nft.tokenId)

  const marketDataForSaleNfts = await getNftsMarketData({ currentSeller: account.toLowerCase() })
  const tokenIdsForSale = marketDataForSaleNfts.map((nft) => nft.tokenId)

  const forSaleNftIds = marketDataForSaleNfts.map((nft) => {
    return { collectionAddress: nft.collection.id, tokenId: nft.tokenId }
  })

  const metadataForAllNfts = await getNftsFromDifferentCollectionsApi([
    ...forSaleNftIds,
    ...walletNftIdsWithCollectionAddress,
  ])

  const completeNftData = combineNftMarketAndMetadata(
    metadataForAllNfts,
    marketDataForSaleNfts,
    walletNftsWithMarketData,
    walletTokenIds,
    tokenIdsForSale,
    profileNftWithCollectionAddress?.tokenId,
  )

  return completeNftData
}

/**
 * Fetch distribution information for a collection
 * @returns
 */
export const getCollectionDistributionApi = async (collectionAddress: string): Promise<ApiCollectionDistribution> => {
  const collectionData = await getCollectionAllInfo(collectionAddress)
  const data = {}

  if(collectionAddress === getPancakeRabbitsAddress().toLowerCase())
  {
    collectionData.nfts?.forEach(async (nft) => {
      if(!(nft.otherId in data)){
        data[nft.otherId] = 1
      }
      else{
        data[nft.otherId] += 1
      }
    });
  }
  else{
    collectionData.nfts?.forEach(async (nft) => {
      if(!(nft.tokenId in data)){
        data[nft.tokenId] = 1
      }
      else{
        data[nft.tokenId] += 1
      }
    });
  }

  const result = {
    "total": Object.keys(data).length,
    "data": data
  }


  return result

  // return {
  //   "total": 1,
  //   "data": {
  //     "not_from_pancake_nft_name": {
  //       "1": 21,
  //       "2": 101,
  //       "3": 101,
  //       "4": 501,
  //       "5": 251,
  //       "6": 100,
  //     }
  //   }
  // }
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/distribution`)
  // if (res.ok) {
  //   const data = await res.json()
  //   return data
  // }
  // console.error(`API: Failed to fetch NFT collection ${collectionAddress} distribution`, res.statusText)
  // return null
}

/**
 * Fetch distribution information for a collection
 * @returns
 */
 export const getCollectionDistributionApiPB = async (collectionAddress: string): Promise<ApiCollectionDistributionPB> => {

  const collectionData = await getCollectionAllInfo(collectionAddress)
  const data = {}

  collectionData.nfts?.forEach(async (nft) => {
    if(!(nft.otherId in data)){
      data[nft.otherId] = 1
    }
    else{
      data[nft.otherId] += 1
    }
  });

  const result = {
    "total": Object.keys(data).length,
    "data": data
  }

  return result
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/distribution`)
  // if (res.ok) {
  //   const data = await res.json()
  //   return data
  // }
  // console.error(`API: Failed to fetch NFT collection ${collectionAddress} distribution`, res.statusText)
  // return null
}
