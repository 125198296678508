import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.PANFIRE]: {
    name: 'Pancake Bunnies',
    slug: 'pancake-bunnies',
    address: {
      56: '0xf36547a7A7749c611333125B8Ce772Dcf50c19D6',
      97: '0xf36547a7A7749c611333125B8Ce772Dcf50c19D6',
      4: '0xb753Ec8Ee4a2B55155615819985e4B7B56549730',
      1: '',
    },
  },
  [CollectionKey.SQUAD]: {
    name: 'Pancake Squad',
    description: "CAMPFIRE's first official generative NFT collection.. Join the squad.",
    slug: 'pancake-squad',
    address: {
      56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
      97: '0xEf12ef570300bFA65c4F022deAaA3dfF4f5d5c91',
      4: '',
      1: '',
    },
  },
}

export default collections
