import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'mailto:campadmin@camp-global.com',
      },
      {
        label: t('Blog'),
        href: 'https://blog.naver.com/camp_global',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: '—',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/campfire-DeFi',
      },
      {
        label: t('Documentation'),
        href: 'https://github.com/campfire-defi/docs',
      },
    ],
  },
]
