import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'CAMPFIRE',
  description:
    'The most popular AMM on ETH by user count! Earn FIRE through yield farming or win it in the Lottery, then stake it in FIRE Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by CAMPFIRE), NFTs, and more, on a platform you can trust.',
  image: 'https://campfire.camp/full-logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('CAMPFIRE')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('CAMPFIRE')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('CAMPFIRE')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('CAMPFIRE')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('CAMPFIRE')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('CAMPFIRE')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('CAMPFIRE')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('CAMPFIRE')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('CAMPFIRE')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('CAMPFIRE')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('CAMPFIRE')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('CAMPFIRE')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('CAMPFIRE')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('CAMPFIRE')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('CAMPFIRE')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('CAMPFIRE')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('CAMPFIRE')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('CAMPFIRE')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('CAMPFIRE Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('CAMPFIRE Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('CAMPFIRE Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('CAMPFIRE')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('CAMPFIRE')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('CAMPFIRE')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('CAMPFIRE')}`,
      }
    default:
      return null
  }
}
